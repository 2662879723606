<template>
    <div class="contain_head">
        <div class="headBox">
            <div class="heardPage">
                <img class="heardPageLogo" src="../assets/logo.png" alt="">
            </div>
            <ul class="heardUl">
                <li v-for="(item, index) in list" :key="index" :class="{ 'active': index == page }"
                    @click="change(index)">{{
                        item.name
                    }}</li>
            </ul>
            <div class="heardLogin" v-if="$route.name != 'Login'">
                <p @click="login">登入</p>
                <p>注册</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "hearDer",
    data() {
        return {
            page: this.$store.state.indexNav,
            list: [
                {
                    name: "首页",
                    index: 0,
                },
                {
                    name: "内容一",
                    index: 1,
                },
                {
                    name: "内容二",
                    index: 2,
                },
                {
                    name: "内容三",
                    index: 3,
                },
                {
                    name: "内容四",
                    index: 4,
                },
            ],
        }
    },
    methods: {
        login() {
            this.$router.push("login")
        },
        change(value) {
            this.page = value
            this.$store.commit("SET_state", value)
        }
    },
}
</script>

<style lang="less" scoped>
.contain_head {
    height: 0.8rem;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: -webkit-linear-gradient(left, #3B0F0F, #121212, #3B0F0F);
    color: #fff;
    font-size: 0.24rem;

    .headBox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .heardPage {
            position: absolute;
            left: 0;

            .heardPageLogo {
                width: 2.12rem;
                vertical-align: middle;
            }
        }
    }

    .heardUl {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        height: 100%;
        align-items: center;

        li {
            width: 1.66rem;
            line-height: 0.8rem;
            text-align: center;

            &:hover {
                background: red;
            }

        }

        .active {
            background: red;
        }
    }

    .heardLogin {
        cursor: pointer;
        display: flex;
        position: absolute;
        right: 38px;

        p {
            background: hsla(0, 0%, 100%, .17);
            padding: 0 5px;
        }
    }
}
</style>