<template>
    <div class="contain_footer">
        我是底部
    </div>
</template>

<script>
export default {
    name: "fooTer",
}
</script>

<style lang="less" scoped>
    .contain_footer{
        height: 100px;
        background: #b3c0d1;
    }
</style>