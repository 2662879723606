export const xm = {
    getItem(key) {
      return JSON.parse(window.localStorage.getItem(key));
    },
    setItem(key, value) {
      return window.localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
      return window.localStorage.removeItem(key);
    },
    getToken() {
      getCookie('token')
    },
    getName() {
      getCookie('account')
    },
    isLogin() {
      return this.getToken() ? true : false;
    },
    go(url, backurl = false) {
      if (backurl) {
        let path = window.location.pathname + (window.location.search ? window.location.search : '')
        sessionStorage.setItem('from_page', path);
      }
      if (typeof url === 'string') {
        if (sessionStorage.getItem('from_page') && backurl === false) {
          sessionStorage.removeItem('from_page');
        }
        window.location.href = window.location.origin + url;
      }
      else if(url === -1) {
        window.history.back();
      }
    },
    // 获取url上信息
    getUrlData() {
      let data =  window.location.hash ? 
        window.location.hash.slice(1).split('&') : 
        window.location.search.slice(1).split('&');
        let obj = {};
        // 出现单个情况
      if (data.length < 2) {
        let objItem = data[0].split('=');
        if (objItem.length < 2) {
          return objItem[0];
        }
        else {
          obj[objItem[0]] = objItem[1];
          return obj;
        }
      }
      else {
        for(let i in data) {
          let objItem = data[i].split('=');
          obj[objItem[0]] = objItem[1];
        }
        return obj;
      }
    },
    // 数字转字符串 10000 => 10,000
    numToString(num, point) {
      if (isNaN(+num)) {
        console.error('输入数字吧 求你了'); 
        return 0;
      }
      let str = '' + num;
      let pointNum = !isNaN(+point) ? point : 3;
      if (pointNum <= 0) pointNum = 3;
      var newStr = ''
      if (str.length <= 3) return str;
      while(str.length > pointNum) {
        if (newStr.length === 0) {
          newStr = str.slice(-pointNum);
        }
        else {
          newStr = str.slice(-pointNum) + ',' + newStr;
        }
        str = str.slice(0, -pointNum);
      }
      return str.slice(-pointNum) + ',' + newStr;
    },
    // 获取时间需要传入new Date()
    getTime(time) {
      let returnTime = {
        y: time.getFullYear(),
        mm: time.getMonth() + 1,
        d: time.getDate(),
        h: time.getHours(),
        m: time.getMinutes(),
        s: time.getSeconds()
      }
      for(let i in returnTime) {
        returnTime[i] = returnTime[i] < 10 ? '0' + returnTime[i] : String(returnTime[i])
      }
      return returnTime;
    },
    // 验证密码
    isPassword(str) {
      let reg = /^[a-zA-Z0-9_-~!@#$%^&*\.?]{6,20}$/;
      return reg.test(str);
    },
    isMail(str) {
      let reg = /^([a-zA-Z0-9_-]+)@([a-zA-Z0-9_-]+)\.([a-zA-Z0-9_-])+$/;
      return reg.test(str);
    },
    isIos() {
      let ua = navigator.userAgent.toLocaleLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  
  export const setCookie = (cname, cvalue, exdays) => {
    let d = new Date()
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000 ))
    let expires = "expires=" + d.toGMTString()
    document.cookie = cname + '=' + cvalue + ';' + expires
  }
  
  export const getCookie = (cname) => {
    var name = cname + '='
    var ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim()
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length)
    }
  }
  
  export const removeCookie = (cname) => {
    setCookie(cname, '', -1)
  }

  export const setItem = (key,value) => {
    return window.localStorage.setItem(key, JSON.stringify(value));
  }

  export const go = (url, backurl = '') => {
    let sld = process.env.NODE_ENV === 'production' ? '/yys/' : ''
    if (backurl) { // 记录跳转前地址
      sessionStorage.setItem('from_page', backurl);
    } else {
      if (sessionStorage.getItem('from_page')) { // 清空记录
        sessionStorage.removeItem('from_page');
      }
    }
  
    if (typeof url === 'string') {
      window.location.href = window.location.origin + sld + url;
    }
  }