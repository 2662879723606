// rem布局配置
// function resetFontSize(max = 500) {
//   var baseFontSize = 50
//   var designWidth = 375
//   var width = (window.innerWidth > max) ? max : window.innerWidth
//   var currentFontSize = (width / designWidth) * baseFontSize
//   document.getElementsByTagName('html')[0].style.fontSize = currentFontSize + 'px'
// }
// window.onresize = function () {
//   resetFontSize()
// };
// resetFontSize()
function flexible (window, document) {
    var docEl = document.documentElement
    var dpr = window.devicePixelRatio || 1
    function setBodyFontSize() {
      if (document.body) {
        document.body.style.fontSize = (12 * dpr) + 'px';
      } else {
        document.addEventListener('DOMContetnLoaded', setBodyFontSize)
      }
    }
  
    function setRemUnit() {
      let baseSize = 100,
        basePc = baseSize / 1920,
        basePhone = baseSize / 750,
        vW = window.innerWidth,
        rem = 0
        console.log(vW)
        if (vW > 750) {
          rem = vW * basePc;
        } else {
          rem = vW * basePhone
        }
        
      docEl.style.opacity = '1'
      docEl.style.fontSize = rem + 'px'
    }
    
  
    window.addEventListener('resize', setRemUnit)
    window.addEventListener('pageshow', function(e) {
      if (e.persisted) {
        setRemUnit()
      }
    })
  
    setBodyFontSize();
    setRemUnit()
  }
  flexible(window, document)
  
  function bodyShow() {
    // let Images = new Image();
    // Images.src = require('@/images/other/alert-bg.png');
    // Images.onload = function() { //图片加载
      document.getElementsByTagName('body')[0].style.opacity = 1
    // }
  }
  bodyShow()
  