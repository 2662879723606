<template>
    <div class="home">
        <Hearder></Hearder>
        <Central></Central>
        <Footer></Footer>
    </div>
</template>

<script>
import Hearder from "../components/hearder";
import Footer from "../components/footer";
import Central from "./central";
export default {
    name: "homePage",
    components: {
        Hearder,
        Footer,
        Central,
    },
    data() {
        return {
        }
    },
    methods: {
    },
}
</script>

<style lang="less" scoped>

</style>