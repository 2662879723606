<template>
    <div class="section">
        <div class="cards">
            <div class="card"></div>
            <div class="card"></div>
            <div class="card"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "central",
    data() {
        return {

        }
    },
}
</script>

<style lang="less" scoped>
.section {
    height: 100vh;
    background: #e9eef3;
    padding-top: 0.8rem;
    .cards{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .card{
            height: 200px;
            background: #7eff82;
            flex: 1 0 200px;
        }
    }
}
</style>