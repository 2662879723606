import { post } from '../js/request'
// import { xm, setCookie } from '@/js/utils'
// import { xm } from '@/js/utils'

// let data =  xm.getUrlData()
// console.log('====================================');
// console.log(data);
// console.log('====================================');
// if (data['token']) {
//     getToken('user/apple_auth');
// } else {
//     xm.go('/');
// }

// token、account存入cooikes
// function savaData(data) {
// let fromPage = sessionStorage.getItem('from_page') || '/'
// setCookie('token', data.token, 30)
// setCookie('account', data.account, 30)
// xm.go(fromPage);
// }

//苹果登录，获取用户信息
// function getToken(path) {
//     post(path, {
//     appId: 10000,
//     token: data['token']
//     }).then(res => {
//     if (!res.code) {
//         savaData(res)
//     } else {
//         alert(res.msg)
//     }
//     });
// }

// 账号登陆
export function login(data) {
  return post('user/login', data)
}
// 账号注册
export function register(data) {
  return post('user/reg', data)
}
// token验证登录状态
export function tokenLogin(token) {
  return post('user/verify', { token: token })
}
// 谷歌登陆
export function googleLogin(token) {
    console.log(token);
  return post('user/google_auth', { token: token, appId: 10000 })
}
// facebook登陆
export function facebookLogin(token) {
  return post('user/facebook_auth', { token: token, appId: 10000 })
}