import axios from 'axios'
axios.defaults.headers = {
  'Content-Type': 'application/json',
}
let baseURL = '';
let url = location.href
if ((/localhost/).test(url)) { // 本地环境
  baseURL = 'https://192.168.5.150:809/v1/'
} else if ((/beliefgame/).test(url)) {  // 测试环境
  baseURL = 'https://api.xiaomaglobe.com/v1/'
} else { // 线上环境
  baseURL = 'https://api.xiaomajoy.tw/v1/'
}

console.log(baseURL)
// let baseURL = 'https://api.xiaomajoy.tw/v1/';

const service = axios.create({
  baseURL: baseURL,
  timeout: 50000
});

// 请求拦截
service.interceptors.request.use(config => {
  return config;
}, err => {
  return new Promise.resolve(err);
});

service.interceptors.response.use(response => {
  let res = response.data
  if (res.code === 0) {
    return res.data
  }
  // 根据返回的code值来做不同的处理(和后端约定)
  return res;
}, (err) => {
  // 返回状态码不为200时候的错误处理
  return console.error(err)
});

export const get = (url, date = {}) => {
  return service({
    method: 'get',
    url: url,
    params: date
  })
}
export const post = (url, date = {}) => {
  return service({
    method: 'post',
    url: url,
    data: date
  })
};
export const from = (url, date = {}) => {
  let fd = new FormData();
  for(let item in date) {
    fd.append(item, date[item])
  }
  return service({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    url: url,
    data: fd
  })
}
